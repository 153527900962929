.rdp-day,
.rdp-button {
  border-radius: 0;
}

.day-selected:not([disabled]),
.day_range_start:not([disabled]),
.day_range_end:not([disabled]) {
  background-color: rgb(40, 160, 219);
  color: #fff;
  border-radius: 0;
}

.day-selected:hover:not([disabled]),
.day_range_end:hover:not([disabled]),
.day_range_start:hover:not([disabled]) {
  background-color: #51a0fa !important;
}

.range-day-selected:not(.day_range_start):not(.day_range_end):not([disabled]) {
  background-color: rgb(246, 249, 251);
  color: rgb(40, 160, 219);
}
